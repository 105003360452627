@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap');
.App {
	box-sizing: border-box;
	font-family: Noto Sans KR, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu,
		Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif !important;
}

.skiptranslate {
	display: none;
}
.translation-links {
	display: none;
	position: fixed;
	right: 10px;
	top: 10px;
}
